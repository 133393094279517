import { useContext, useState, ReactNode } from "react";
import { ResponsiveContext } from "../../ResponsiveContext.js";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Typography, Icon } from "@mui/material";
import "./Chart.scss";
import StatusChip from "../common/StatusChip";

type ContextProp = {
  isSmallScreen: boolean;
  isExtraSmallScreen: boolean;
};

enum ErrorState {
  OK = 0,
  MAINTENANCE = 1,
  ISSUES = 2,
  ERROR = 3,
}

type ChartProps = {
  title: string;
  subTitle?: string;
  errorState?: ErrorState;
  errorDescription?: string;
  children: ReactNode;
  additionalInfo?: ReactNode;
};

const Chart = ({
  title,
  subTitle,
  errorState,
  children,
  additionalInfo,
}: ChartProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const contextValue = useContext(ResponsiveContext);
  const { isSmallScreen, isExtraSmallScreen } = contextValue as ContextProp;

  return (
    <Accordion
      elevation={0}
      expanded={expanded}
      onChange={() => setExpanded((prevStatus) => !prevStatus)}
      className="chart-accordion"
      sx={{
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
        <div className="accordion-summary">
          <div className="summary-title">
            <Typography variant="body2">{title}</Typography>
            {!isSmallScreen && !isExtraSmallScreen && (
              <Typography className="body3">{subTitle}</Typography>
            )}
          </div>

          <div className="summary-extras">
            {additionalInfo && (
              <div className="summary-additionals">{additionalInfo}</div>
            )}
            {errorState !== undefined && (
              <div className="summary-state">
                <StatusChip status={errorState} />
              </div>
            )}
          </div>
        </div>
      </AccordionSummary>

      {expanded && (
        <AccordionDetails style={{ padding: 0 }}>{children}</AccordionDetails>
      )}
    </Accordion>
  );
};

export default Chart;

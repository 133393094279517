import { useContext } from "react";
import { ResponsiveContext } from "../../ResponsiveContext.js";
import {
  STATUS_COLOR_BAR_BORDER,
  formatDateHHMM,
  formatMSasSeconds,
  formatInteger,
} from "../../Utils";
import Chart from "../main/Chart";
import {
  LineChart,
  ResponsiveContainer,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { l } from "../../Lang";
import "./UptimeLineChart.scss";

type UptimeLineChartProps = {
  metric: any;
};

type ContextProp = {
  isLargeScreen: boolean;
  isMediumScreen: boolean;
  isSmallScreen: boolean;
  isExtraSmallScreen: boolean;
  l: any; // Replace 'any' with the actual type of 'l' if possible
};

const tooltipText = {
  "agent-access-time": "segundos",
  "agent-get-message-time": "segundos",
  "online-agents": "agentes",
  "status-queue-waiting": "usuarios",
  "status-queue-waiting-secs": "segundos",
  "status_wapp_mon-api-send-message": "mensajes",
  "status_wapp_mon-operator-msg": "mensajes",
  "status_wapp_mon-user": "mensajes",
  "web-console-access": "agentes",
  "web-console-get-customers": "agentes",
  "web-console-get-messages": "mensajes",
};

const formatTooltip = (metricName: string, value: number) => {
  const text = tooltipText[metricName as keyof typeof tooltipText] ?? "";
  const formattedValue =
    text === "segundos" ? formatMSasSeconds(value) : formatInteger(value);

  return `${formattedValue} ${l(text)}`;
};

const AMOUNT_TO_SHOW = 100;
const AMOUNT_TO_SHOW_MOBILE = 8;

const UptimeLineChart = ({ metric }: UptimeLineChartProps) => {
  const { metricName, points, desc, label } = metric;
  const contextValue = useContext(ResponsiveContext);
  const { isExtraSmallScreen } = contextValue as ContextProp;

  const pointsToShow = points
    .slice(isExtraSmallScreen ? -AMOUNT_TO_SHOW_MOBILE : -AMOUNT_TO_SHOW)
    .map((point: any) => ({
      ...point,
      value: Math.round(point.value),
      iso: formatDateHHMM(point.iso),
    }));

  const CustomTooltip = (props: any) => {
    const { active, payload, label } = props;
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <div className="custom-tooltip-text">
            {formatTooltip(metricName, payload[0].value)}
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <Chart title={label} subTitle={desc}>
      <span className="uptime-chart-container height-100">
        <ResponsiveContainer className="container" height={275}>
          <LineChart
            data={pointsToShow}
            margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
          >
            <CartesianGrid vertical={false} stroke="var(--c-gray-100)" />
            {/* <ReferenceLine y={100} stroke="red" strokeDasharray="3 3" /> */}
            <XAxis dataKey="iso" axisLine={false} tickLine={false} />
            <YAxis axisLine={false} tickLine={false} />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey="value"
              stroke={STATUS_COLOR_BAR_BORDER[0]}
            />
          </LineChart>
        </ResponsiveContainer>
      </span>
    </Chart>
  );
};

export default UptimeLineChart;
